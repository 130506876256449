@import 'src/constants/colours';

.imagen-dark-purple-bg {
  background-color: $imagen-dark-purple;
}

.imagen-purple {
  color: $imagen-purple;
}

.imagen-purple-bg {
  background-color: $imagen-purple;
}

.imagen-gray-100-bg {
  background-color: $imagen-gray-100;
}

.imagen-light-purple {
  color: $imagen-light-purple;
}

.imagen-light-purple-bg {
  background-color: $imagen-light-purple;
}

.imagen-gray-400 {
  color: $imagen-gray-400;
}

.imagen-gray-500 {
  color: $imagen-gray-500;
}

.imagen-gray-600 {
  color: $imagen-gray-600;
}

.imagen-gray-700 {
  color: $imagen-gray-700;
}

.imagen-danger {
  color: $imagen-danger;
}

.imagen-danger-bg {
  background-color: $imagen-danger;
}

.imagen-success-bg {
  background-color: $imagen-success;
}

.imagen-gray-700-bg {
  background-color: $imagen-gray-700;
}

.icon-white path {
  stroke: white;
}

.icon-grey path {
  stroke: $imagen-gray-400;
}
