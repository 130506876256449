@charset "utf-8";
@import 'utils/reset';
@import 'constants/colours';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

$primary: $imagen-purple;
$dark: $imagen-admin;
$gray-500: $imagen-gray-500;
$gray-700: $imagen-gray-700;
$gray-900: $imagen-gray-900;
$danger: $imagen-danger;
$body-bg: $imagen-body;
$border-radius: .1875rem;
$border-color: $imagen-gray-200;
$card-border-radius: .5rem;

$font-family-base: Inter, sans-serif;


@import '~bootstrap/scss/bootstrap';
@import 'utils/typography';
@import 'utils/colours';
@import 'utils/layout';

.disabled {
  pointer-events: none;
}

[disabled] {
  cursor: not-allowed;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.pac-container {
  z-index: #{$zindex-modal + 1};
}
