@import 'src/utils/utils';

.chevron {
  transition: transform .5s;
  height: 1rem;

  &-admin path, &-brand path {
    stroke: $imagen-gray-500;
  }

  &-consultant path {
    stroke: white;
  }

  &.open {
    transform: rotate(180deg);
  }
}
